import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import css from "./About.module.scss"
import HeaderNav from "../HeaderNav/HeaderNav"
import RichTextRender from "../../components/RichTextRender/RichTextRender"
import Img from "gatsby-image"

class About extends Component {
  constructor(props) {
    super(props)

    this.state = {
      titleLoaded: false,
    }
  }
  render() {
    let documentRichText = this.props.data.allContentfulAbout.edges[0].node.description.json
    return (
      <div className={css.container}>
        <div className={this.state.titleLoaded ? css.navHeaderContainerTransition : css.navHeaderContainer}>
          <HeaderNav titleLoaded={true} backgroundWhiteColor={true}></HeaderNav>
        </div>
        <div className={css.pageWrapper}>
          <Img sizes={this.props.data.allContentfulAbout.edges[0].node.image.image.sizes} className={css.gatsbyImageWrapper} />
          <div className={css.textWrapper}>
            <RichTextRender documentRichText={documentRichText}></RichTextRender>
          </div>

          <div className={css.textAuthorFooterContainer}>
            <div class="svg-wrapper">
              <svg height="80" width="420" xmlns="http://www.w3.org/2000/svg">
                <rect class="shape" height="80" width="420" />
              </svg>
            </div>
            <div className={[css.textAuthorFooter, 'author-name'].join(' ')}><i>For contact email <br></br> StoryEtFallCommunity@gmail.com</i></div>
          </div>
        </div>
      </div>
    )
  }
}
export default props => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulAbout {
          edges {
            node {
              description {
                json
              }
              image {
                image {
                  sizes(maxWidth: 1080) {
                      ...GatsbyContentfulSizes
                    }
                  file {
                    url
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <About data={data} {...props} />}
  />
)
