import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import About from "../containers/About/About";
// import Image from "../components/image"
// import Test from "../components/Test/test"

const IndexPage = () => (
  <Layout>
    <SEO title="About" />
    <About></About>
  </Layout>
)

export default IndexPage
